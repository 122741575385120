import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import React from 'react'

import KickflipLogo from 'icons/logos/kickflip.svg'

export interface Action {
  label: string
  onClick: () => void
}

export interface ErrorPageProps {
  title: string
  description: React.ReactNode
  action?: Action
  helpLink?: string
}

const ErrorPage = ({ title, description, action, helpLink }: ErrorPageProps) => {
  return (
    <div className="flex pb-10 pt-10 justify-center w-full">
      <div className="flex flex-col max-w-lg p-6 w-full justify-center items-center text-center">
        <KickflipLogo className="text-primary-600 w-24 mb-7" />
        <div className="flex flex-col items-center">
          <h2>{title}</h2>

          <p className="my-4">{description}</p>

          <div className="flex mt-3 space-x-2">
            {helpLink && (
              <Button
                iconPosition="right"
                icon={<Icons.Help className="w-4 h-4" />}
                onClick={() => window.open(helpLink, '_blank')}
              >
                Learn more
              </Button>
            )}
            {action && (
              <Button variant="primary" onClick={action.onClick}>
                {action.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
